<script setup lang="ts">
import { Multiselect } from 'vue-multiselect'
import type { ZipCodeDetails } from '~/server/helpers/zipResolver'
import type { VueMultiselect } from '~/types/vue-multiselect'

interface Props { id: string }

const props = withDefaults(defineProps<Props>(), {
  id: 'multiselect-zip-picker',
})

// events
const emit = defineEmits<{
  (e: 'select', option: ZipCodeDetails, label: string): void
}>()

// refs
const zipValue = defineModel<string>()
const multiSelectRef = ref<VueMultiselect<ZipCodeDetails> | null>(null)
const selectedOption = ref<ZipCodeDetails>()
const searchQuery = ref('')
const options = ref<ZipCodeDetails[]>([])
const loading = ref(false)

// helper functions
const placeholder = computed(() => {
  return zipValue.value || 'Enter ZIP code'
})

function createLabel(item: ZipCodeDetails): string {
  return `${item.zip} ${item.city}, ${item.state}`
}
function activatePicker() {
  multiSelectRef.value?.activate()
}
defineExpose({
  activatePicker,
})

const lastSearch = ref('')
/** fetching data */
watchEffect(async () => {
  const search = searchQuery.value.slice(0, 3)
  if (search.length === 3) {
    loading.value = true
    if (lastSearch.value !== search) {
      lastSearch.value = search
      const data = await $fetch(`/api/zip-autocomplete?zip=${searchQuery.value.slice(0, 3)}`).catch()
      options.value = data || []
    }
    loading.value = false
  }
  else {
    options.value = []
  }
})

// event handlers

// doing this to sanitize input
function onSearchChange(search: string) {
  const sanitized = search.replace(/\D+/g, '').trim().slice(0, 5)
  if (multiSelectRef.value!.search !== sanitized) {
    multiSelectRef.value!.search = sanitized
  }
  else {
    searchQuery.value = sanitized
  }
}

function onSelect(option: ZipCodeDetails) {
  emit('select', option, createLabel(option))
  selectedOption.value = option
  zipValue.value = option.zip
}

function onOpen() {
  if (zipValue.value) {
    multiSelectRef.value!.search = ''
    multiSelectRef.value!.search = zipValue.value
  }
}

function onTab() {
  const select = multiSelectRef.value
  if (select) {
    if (select.filteredOptions.length > 0) {
      const option = select.filteredOptions[select.pointer]
      zipValue.value = option.zip
      select.toggle()
    }
  }
}

const shouldHideContentWrapper = computed(() => {
  return !searchQuery.value.length
    || !(multiSelectRef.value?.filteredOptions?.length)
})
</script>

<template>
  <div class="cs-search-wrapper">
    <Multiselect
      :id="props.id"
      ref="multiSelectRef"
      :class="{ 'cs-hide-content-wrapper': shouldHideContentWrapper }"
      :options="options"
      :internal-search="true"
      :reset-after="false"
      open-direction="bottom"
      :placeholder="placeholder"
      :custom-label="createLabel"
      track-by="zip"
      :loading="loading"
      :show-no-results="searchQuery.length >= 3"
      :clear-on-select="false"
      :close-on-select="true"
      :free-text-search="false"
      :preserve-search="true"
      :show-no-options="false"
      @keydown.tab="onTab"
      @search-change="onSearchChange"
      @open="onOpen"
      @select="onSelect"
    >
      <template #noResult>
        <div class="cs-no-results">
          No Results
        </div>
      </template>

      <template #noOptions>
        <div class="cs-no-results">
          Enter Zip Code
        </div>
      </template>
    </Multiselect>
  </div>
</template>
